import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import { renderParagraphs } from "@helper/paragraph";
import { NextRouter, useRouter } from "next/router";
import React from "react";
import { NodeProps } from "@model/node.model";
import Breadcrumbs from "@components/Common/Breadcrumbs/Breadcrumbs";
import useJournalSlugTopicData from "@hooks/NextJournal/useJournalSlugTopicData";
import useJournalSlugArticles from "@hooks/NextJournal/useJournalSlugArticles";
import ContentHeader from "@components/Modules/NextJournal/ContentHeader/ContentHeader";
import CategoryCardsSection from "@components/Modules/NextJournal/CategoryCardsSection/CategoryCardsSection";
import ArticleCardGrid from "@components/Modules/NextJournal/ArticleCardGrid/ArticleCardGrid";
import Spinner from "@components/Modules/NextJournal/Spinner/Spinner";
import { isFocusCategory } from "@components/Modules/NextJournal/constants";

const TaxonomyTermCategoriaArticolo: React.FC<NodeProps> = ({ node, articlesPerSubCategory }) => {
  const router: NextRouter = useRouter();
  const { topic: topicId, page = '1'} = router?.query;
  const isMobile: boolean = useMediaQuery(useTheme().breakpoints.down("lg"));

  const paragraphs = node?.field_paragraphs ? renderParagraphs(node) : null;

  const { pageTitle } = useJournalSlugTopicData({ topicId, node, isArticlePage: false, categoryTitle: node?.name });
  
  const { items, infiniteScrollTarget, isLoading, totalItems, onChangePage, count,  } = useJournalSlugArticles({isArticlePage: false, topicId, categoryId: node?.id, infiniteScroll: true});

  const isFocus: boolean =  node?.name === 'NEXT Focus'
  
  return (
    <>
      <Breadcrumbs node={node} />

      {paragraphs}

      <Container maxWidth="xl" sx={{pb:{sm: 300, lg: 120}}}>

        {isFocus ? (
          articlesPerSubCategory?.map((subCategory, i) => ( <CategoryCardsSection key={subCategory.name} {...subCategory} i={i}/> ))
        ) : (
          <>
            <Box sx={{ pt: { xs: 0, lg: '10rem' } }}>
              <ContentHeader isMobile={isMobile} title={pageTitle}/>
            </Box>
            <ArticleCardGrid articles={items} cardVariant={isFocusCategory(node?.name) ? 'complete': 'default'} />
            {isLoading ? <Spinner active /> : <></>}
          </>
        )}

      </Container>
    </>
  );
}

export default TaxonomyTermCategoriaArticolo